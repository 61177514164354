function Anthony() {
    return ( <div
        className="column centerAlign"
        style={{ padding: "2vw", backgroundColor: "#444444"}}
      >

        <img src={require("../assets/anthony.jpg")} alt="anthony" style={{width: "30vw", height: "30vw"}}></img>
        <h1>anthony</h1>

      </div> );
}

export default Anthony;